import './App.css';
import {gsap} from 'gsap';
import React, {useEffect, useRef} from 'react';

function Home() {
    const imageRef = useRef(null);
    const textRef = useRef(null);
    useEffect(() => {
        // GSAP animations for image and text
        gsap.fromTo(imageRef.current, {y: 50, opacity: 0}, {y: 0, opacity: 1, duration: 1.5});
        gsap.fromTo(textRef.current, {x: 50, opacity: 0}, {x: 0, opacity: 1, duration: 1.5, delay: 0.5});
    }, []);

    return (
        <>
            <section className="hero-section">
                <img src={require("./leeva_logo.png")} className="logo" alt="Leeva Investment"/>
                <div className="hero-content">
                    <div className="image-container" ref={imageRef}>
                        <img
                            src={require("./img_home.png")}
                            srcSet={`${require("./img_home.png")} 480w, ${require("./img_home.png")} 800w`}
                            alt="Revolut Security"
                        />
                    </div>
                    <div className="text-container" ref={textRef}>
                        <h1>Exclusivity at the service of your wealth</h1>
                        <p>
                            Join an exclusive circle of privileged investors and gain access to unique opportunities. We
                            offer tailored wealth management, combining discretion, performance, and expertise to grow
                            and protect your assets within a secure and exclusive environment.
                        </p>
                        <a href="https://calendly.com/yanilbbrd/45min" className="cta-btn">Become a member</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;