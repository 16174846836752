import React from 'react';
import './LegalTerms.css';

function LegalTerms() {
    return (
        <div className="legal-terms">
            <a href="/"><h1>LEEVA INVESTMENT</h1></a>
            <h1>Legal Terms</h1>
            <p>Welcome to Leeva, an exclusive wealth management club. By accessing our website and services, you agree
                to the following terms and conditions:</p>

            <h2>1. Legal Information</h2>
            <p>Leeva is a private wealth management club, exclusively available to selected individuals. Our services
                are subject to specific legal and regulatory requirements.</p>

            <h2>2. Access to the Website</h2>
            <p>The content of this website is intended for informational purposes only and does not constitute an offer
                or solicitation of investment services. Access to certain sections of this website may be restricted to
                verified members of Leeva.</p>

            <h2>3. Limitation of Liability</h2>
            <p>Leeva makes every effort to ensure the accuracy of the information provided on this site. However, we do
                not guarantee the accuracy, completeness, or relevance of the content. Leeva cannot be held responsible
                for any direct or indirect loss or damage resulting from the use of this website.</p>

            <h2>4. Intellectual Property</h2>
            <p>All content, including but not limited to logos, texts, graphics, and images, is the intellectual
                property of Leeva unless stated otherwise. Any unauthorized reproduction or distribution of this content
                is strictly prohibited.</p>

            <h2>5. Governing Law</h2>
            <p>These terms and conditions are governed by the laws of France. Any disputes arising from the use of this
                website will be subject to the exclusive jurisdiction of the courts of France.</p>

            <h2>6. Changes to the Terms</h2>
            <p>Leeva reserves the right to modify these terms at any time. We advise you to regularly check this page
                for updates.</p>

            <p>Last updated: october 1st 2024</p>
        </div>
    );
}

export default LegalTerms;
