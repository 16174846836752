import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {gsap} from 'gsap';
import Home from "./Home";
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import LegalTerms from "./LegalTerms";
import PrivacyPolicy from "./PrivacyPolicy"; // Import React Router components


function App() {
    const [isModalOpen, setIsModalOpen] = useState(false); // État pour gérer l'affichage du modal

    const handleOpenModal = () => {
        setIsModalOpen(true); // Ouvrir le modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Fermer le modal
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Traitement du formulaire ici
        console.log('Form submitted!');
        handleCloseModal();
    };

    return (
        <div className="App">
            {/*<Home/>*/}
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/terms" element={<LegalTerms/>}/>
                    <Route path="/privacy" element={<PrivacyPolicy/>}/>
                </Routes>
            </Router>
            {/*<Home handleOpenModal={handleOpenModal}/>*/}
            {/* Modal for Become a Member */}
            {/*<Modal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmit}/>*/}

            <footer className="footer">
                <div className="footer-content">
                    <p>© 2024 Leeva Investment (LUMY). All rights reserved.</p>
                    <p>Leeva Investment (LUMY) is a registered trademark. | <a href="/terms">Legal Terms</a> | <a
                        href="/privacy">Privacy Policy</a></p>
                </div>
            </footer>

        </div>
    );
}

export default App;
