import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <a href="/"><h1>LEEVA INVESTMENT</h1></a>
            <h1>Privacy Policy</h1>
            <p>At Leeva, we value your privacy and are committed to protecting your personal data. This Privacy Policy
                outlines how we collect, use, and protect your information when you use our services.</p>

            <h2>1. Data Collection</h2>
            <p>We collect personal data that you provide when you apply for membership or use our services. This data
                may include your name, contact details, financial information, and any other details necessary for us to
                provide our wealth management services.</p>

            <h2>2. Use of Data</h2>
            <p>Leeva uses your personal data to offer customized wealth management services, maintain member
                communication, and ensure compliance with regulatory obligations. We will not sell or disclose your
                information to third parties without your consent, unless required by law.</p>

            <h2>3. Data Security</h2>
            <p>We implement strict security measures to protect your personal data from unauthorized access, alteration,
                or disclosure. However, while we strive to protect your personal data, no security measures are perfect,
                and we cannot guarantee complete security of your information.</p>

            <h2>4. Your Rights</h2>
            <p>You have the right to access, correct, or request the deletion of your personal data at any time.</p>

            <h2>5. Data Retention</h2>
            <p>We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy, or
                as required by law.</p>

            <h2>6. Changes to This Policy</h2>
            <p>Leeva reserves the right to modify this Privacy Policy at any time. Any changes will be reflected on this
                page, and we encourage you to review this policy periodically.</p>

            <p>Last updated: october 1st 2024</p>
        </div>
    );
}

export default PrivacyPolicy;
